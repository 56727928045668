import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { Locale } from "../context/Locale"

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en"
  }

  const lang = navigator?.language?.split("-")[0]
  if (!lang) return "en"

  return lang === "fr" ? "fr" : "en"
}

const Index = () => {
  const { setLocale } = useContext(Locale)

  useEffect(() => {
    const urlLang = getRedirectLanguage()
    setLocale(urlLang)
    navigate(`/${urlLang}`, { replace: true })
  }, [setLocale])

  return null
}

export default Index
